<template>
  <v-row justify="center">
    <v-col cols="8">
      <v-card>
        <v-card-title>
          <h3>Список прокси</h3>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th>ID</th>
                <th>Магазин</th>
                <th>Годен до</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(proxy, i) in proxies" :key="i" @click="show(i)">
                <td>{{proxy.id}}</td>
                <td>{{proxy.website}}</td>
                <td>{{proxy.valid_thru}}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-spacer></v-spacer>
          <v-btn class="mt-3" color="primary" @click="selectedProxy = {}; dialog=true">Добавить</v-btn>
        </v-card-text>
        <v-dialog v-model="dialog" width="1000">
          <proxy :proxy="selectedProxy" @closeDialog="dialog = false; load()"></proxy>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import httpClient from '@/services/http.service'
import Proxy from '@/views/proxies/Proxy'

export default {
  name: 'ShowProxies',
  components:{Proxy},
  data(){
    return{
      proxies: [],
      selectedProxy: {},
      dialog: false,
    }
  },
  created() {
    this.load()
  },
  methods:{
    load(){
      httpClient.get('proxies').then(({data})=>{
        this.proxies = data
      })
    },
    show(i){
      this.selectedProxy = this.proxies[i]
      this.dialog = true
    }
  }
}
</script>

<style scoped>

</style>
