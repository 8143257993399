<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-row>
            <v-col>
              <v-text-field
                v-model="proxy.website"
                label="Адрес магазина"
                placeholder="Адрес магазина"
                outlined
              >
              </v-text-field>
              <v-text-field
                v-model="proxy.site_login"
                label="Логин магазина"
                placeholder="Логин магазина"
                outlined
              >
              </v-text-field>
              <v-text-field
                v-model="proxy.site_password"
                :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? mdiEyeOff : mdiEye"
                label="Пароль магазина"
                placeholder="Пароль магазина"
                outlined
                @click:append="show1 = !show1"
              >
              </v-text-field>
              <v-menu
                ref="menu"
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="proxy.valid_thru"
                    label="Годен до"
                    :prepend-icon="mdiCalendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="proxy.valid_thru"
                  :active-picker.sync="activePicker"
                ></v-date-picker>
              </v-menu>
              <v-btn
                :disabled="dialog"
                :loading="dialog"
                class="white--text"
                color="primary"
                @click="dialog = true; save()"
              >
                Сохранить
              </v-btn>
              <v-dialog
                v-model="dialog"
                hide-overlay
                persistent
                width="300"
              >
                <v-card
                  color="primary"
                  dark
                >
                  <v-card-text>
                    Сохраняем...
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row>
            <v-col>
              <v-text-field
                v-model="proxy.https_ip"
                label="HTTPS IP"
                placeholder="HTTPS IP"
                outlined
              >
              </v-text-field>
              <v-text-field
                v-model="proxy.https_port"
                label="HTTPS порт"
                placeholder="HTTPS порт"
                outlined
              >
              </v-text-field>
              <v-text-field
                v-model="proxy.https_login"
                label="HTTPS логин"
                placeholder="HTTPS логин"
                outlined
              >
              </v-text-field>
              <v-text-field
                v-model="proxy.https_password"
                :type="show2 ? 'text' : 'password'"
                :append-icon="show2 ? mdiEyeOff : mdiEye"
                label="HTTPS пароль"
                placeholder="HTTPS пароль"
                outlined
                @click:append="show2 = !show2"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row>
            <v-col>
              <v-text-field
                v-model="proxy.sock5_ip"
                label="SOCK5 IP"
                placeholder="SOCK5 IP"
                outlined
              >
              </v-text-field>
              <v-text-field
                v-model="proxy.sock5_port"
                label="SOCK5 порт"
                placeholder="SOCK5 порт"
                outlined
              >
              </v-text-field>
              <v-text-field
                v-model="proxy.sock5_login"
                label="SOCK5 логин"
                placeholder="SOCK5 логин"
                outlined
              >
              </v-text-field>
              <v-text-field
                v-model="proxy.sock5_password"
                :type="show3 ? 'text' : 'password'"
                :append-icon="show3 ? mdiEyeOff : mdiEye"
                label="SOCK5 пароль"
                placeholder="SOCK5 пароль"
                outlined
                @click:append="show3 = !show3"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiEyeOff, mdiEye, mdiCalendar } from '@mdi/js'
import QueryString from 'qs'
import httpClient from '@/services/http.service'

export default {
  name: 'Proxy',
  props:{
    proxy:{
      type: Object,
      default: () => {}
    },
  },
  data(){
    return{
      mdiEyeOff, mdiEye, mdiCalendar,
      dialog: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
    }
  },
  methods:{
    save(){
      if (this.proxy.hasOwnProperty('id')){
        httpClient.put(`/proxies/${this.proxy.id}`, QueryString.stringify(this.proxy)).then(()=>{
          this.dialog = false
          this.$emit('closeDialog')
        })
      }else{
        httpClient.post('/proxies', QueryString.stringify(this.proxy)).then(()=>{
          this.dialog = false
          this.$emit('closeDialog')
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
